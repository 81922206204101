/**
 * Open Sans
 *
 * 400 = regular
 * 700 = bold
 */

//@import "./Fonts/ModernBrowsers/OpenSans/OpenSans-regular-latin";
//@import "./Fonts/ModernBrowsers/OpenSans/OpenSans-italic-latin";
//
//@import "./Fonts/ModernBrowsers/OpenSans/OpenSans-700-latin";
//@import "./Fonts/ModernBrowsers/OpenSans/OpenSans-700italic-latin";

/**
 * Montserrat-regular
 */

@import "./Fonts/ModernBrowsers/Montserrat/Montserrat-400-latin";
@import "./Fonts/ModernBrowsers/Montserrat/Montserrat-400italic-latin";

@import "./Fonts/ModernBrowsers/Montserrat/Montserrat-700-latin";
@import "./Fonts/ModernBrowsers/Montserrat/Montserrat-700italic-latin";

@import "./Fonts/PressStartAll";
@import "./Fonts/ModernBrowsers/Poppins/Poppins-400-latin";
@import "./Fonts/ModernBrowsers/Poppins/Poppins-600-latin";
@import "./Fonts/ModernBrowsers/Poppins/Poppins-800-latin";
