.ot-icon {
    width: 32px;
    height: 32px;
    fill: #fff;
}

aside.left-side {
    .social-media-kontakt > div {
        margin: 1rem 1.8rem;
    }

    .ot-icon-instagram:hover {
        fill: $color-instagram;
    }

    .ot-icon-facebook:hover {
        fill: $color-facebook;
    }

    .ot-icon-envelope:hover {
        fill: black;
    }

    .ot-icon-phone:hover {
        fill: black;
    }
}

#main-nav-mobile .ot-icon-caret-down {
    margin-top: -0.3em !important;
    fill: #be0064;
    width: 1.5em;
    height: 1.5em;
}
#main-nav-mobile .collapsed .ot-icon-caret-down {
    margin-top: 0.1em !important;
    rotate: 180deg;
}

#desktop-navigation .ot-icon-caret-down {
    margin-top: -0.6em;
    fill: #be0064;
    width: 2em;
    height: 2em;
}
