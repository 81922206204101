.frame-default {
    width: 100%;
}

#desktop-navigation {
    z-index: 1021 !important;

    .inner {
        padding-bottom: 4rem;
    }
}

.page-transition {
    z-index: 1040 !important;
}

#main > aside {
    z-index: 1022 !important;
}


footer {
    background-color: #57606b !important;

    .footer-headline {
        font-weight: bold;
        margin-bottom: 1.5rem;
        display: inline-block;
    }

    .footer-h3 {
        font-size: 1.2rem;
    }

    ul.nav li a {
        padding-left: 0;
    }

    a {
        color: #fff !important;
    }
    a:hover {
        color: #000 !important;
    }
}


.ot-ext-responsiveimages img.img-fluid {
    border: 1px solid rgba(var(--bs-primary-rgb)) !important;
}

// TEMP Test rounded
.ot-ctype-ot_responsiveimages img {
    border-radius: var(--bs-border-radius);
}
.card,
.ot-ctype-ot_responsiveimages .card {
    border-radius: var(--bs-border-radius);
}

.card img,
.card video {
    border-top-left-radius:  var(--bs-border-radius);
    border-top-right-radius:   var(--bs-border-radius);
}
