/**
 * ABSTRACTS
 */
@import "Abstract/variables";
@import 'Abstract/bootstrap';

@import "Abstract/functions";
@import "Abstract/globals";
@import "Abstract/mixins";

/**
 * COMPONENTS
 */
@import "Components/bootstrap";
/*@import "@fancyapps/ui/src/Fancybox/Fancybox";*/
/*@import "@fancyapps/ui/src/Carousel/Carousel";*/

@import "../../private/typo3conf/ext/ot_socialmediawall/Resources/Private/Scss/OtSocialMediaWall.scss";
/**
 * FONTS
 */
/*@import "Fonts/OpenSans";*/
/*@import "Fonts/TTNormsPro";*/

/**
 * ELEMENTS
 */
/*@import "Elements/buttons";*/
/*@import "Elements/contactButton";*/
/*@import "Elements/navigation";*/
/*@import "Elements/sidebar";*/
/*@import "Elements/typography";*/
/*@import "Elements/svg";*/
/*@import "Elements/scrollTop";*/
/*@import "Elements/cards";*/
/*@import "Elements/additionalClasses";*/
/*@import "Elements/tables";*/
/*@import "Elements/cookieConsent";*/
/*@import "Elements/autocolumnText";*/
/*@import "Elements/accordeon";*/
/*@import "Elements/forms";*/
/*@import "Elements/tabs";*/
/*@import "Elements/blog";*/
/*@import "Elements/modal";*/
/*@import "Elements/flashMessages";*/
/*@import "Elements/offcanvas";*/
/*@import "Elements/rapidmail";*/
/*@import "Elements/lists";*/
/*@import "Elements/textmedia";*/
/*@import "Elements/footer";*/
/*@import "Elements/lightbox";*/
/*@import "Elements/spinner";*/

/**
 * UTILITIES
 */
@import "Utilities/colors";
@import "Utilities/fonts";

/**
 * LAYOUTS
 */
@import "Layout";
@import "Fonts";
@import "Typography";
/*@import "BackendLayouts/homepage";*/
/*@import "BackendLayouts/hero";*/

/**
 * CONTENT ELEMENTS
 */
/*@import "ContentElements/otBtCarousel";*/
/*@import "ContentElements/slider";*/
/*@import "ContentElements/heroContainer";*/
/*@import "ContentElements/certificates";*/
/*@import "ContentElements/video";*/
/*@import "ContentElements/gsw-assistant-form";*/
/*@import "ContentElements/avatar";*/
/*@import "ContentElements/discount-code";*/
/*@import "ContentElements/accordion";*/
/*@import "ContentElements/charts";*/
/*@import "ContentElements/frames";*/
/*@import "ContentElements/iconText";*/
/*@import "ContentElements/headline";*/
/*@import "ContentElements/headlineDisturber";*/
@import "ContentElements";

@import "ContentElements/cards";

/**
 * VARIANTS
 */

/* Old*/
@import "./Old/hamburger";
@import "./Old/swiper";
@import "./Old/style";

/*
 * Navbar
 */
@import "Navigation";
@import "PageLayout";
@import "Icons";
@import "Forms";
@import "Buttons";
@import "ContentElements/OtFaq";

footer, footer .nav-link {font-size: 1em !important}
footer p {
    margin-bottom: 2.1em;
}
#ce7 .h3 {
    margin-bottom: 2.35em;
}

/* Wechsel Nav -> Desktop*/
@include media-breakpoint-up(lg) {
    body {
        padding-left: 120px;
    }
    footer.footer {
        padding: 0 120px;
    }
}

/* Gradient Header*/
/*.content-container {*/
/*    h2:not(.accordion-header) {*/
/*        font-family: Montserrat, "Open Sans", sans-serif;*/
/*        //background: #fff;*/
/*        //color: #be0064 !important;*/
/*        //padding: 0 0.5em;*/
/*        display: inline-block;*/
/*        font-weight: 800;*/
/**/
/*        background: linear-gradient(90deg, #a090ce, #cb3383, #be0064);*/
/**/
/*        -webkit-background-clip: text;*/
/*        -webkit-text-fill-color: transparent;*/
/*    }*/
/*}*/
/*.content-container .reverse-gradient {*/
/*    background-image: linear-gradient(90deg, rgb(190, 0, 100) 0%,  rgb(203, 51, 131) 50%, rgb(160, 144, 206) 100%) !important;*/
/*}*/

@include media-breakpoint-up(lg) {
    #MenuLabel {
        font-family: "Press Start 2P", sans-serif;
        position: absolute;
        z-index: 10000;
        color: #fff;
        display: block;
        top: 22%;
        left: -38px;
        font-size: 0.7em;
        transform: rotate(-90deg);
        text-transform: uppercase;
    }
    /*#MenuText {*/
    /*    font-family: 'Press Start 2P', sans-serif;*/
    /*    position: fixed;*/
    /*    //z-index: 10000;*/
    /*    //z-index: 12;*/
    /*    z-index: 1023;*/
    /*    color: #fff;*/
    /*    display: block;*/
    /*    top: 41.5%;*/
    /*    left: 0;*/
    /*    font-size: 0.7em;*/
    /*    transform: rotate(-90deg);*/
    /*    text-transform: uppercase;*/
    /*}*/
}
#box {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*height: auto;*/
    /*color: white;*/
    /*font-size: 2.5rem;*/
}
main.container .gradient-border {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    color: white;
    font-size: 2.5rem;

    --borderWidth: 5px;
    /*background: #1D1F20;*/
    position: relative;
    border-radius: var(--borderWidth);

    margin: 3rem 0.4rem;

    a {
        color: #fff;
        display: block;
        /*background: #111;*/
        position: relative;
        z-index: 2;
        width: 100%;
        /*border-radius: var(--borderWidth);*/
        border-radius: 10px;
        line-height: 1.5em;
        text-align: center;
        font-weight: bold;
        padding: 2em 0;
        text-decoration: none;
        /*padding-bottom: 2em !important;*/
        border: none !important;
        font-size: 1.1rem;
    }
}

.gradient-border:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    /*background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);*/
    background: linear-gradient(60deg,#f294ed,#f37055,#ef4e7b,#a166ab);
    /*border-radius: calc(2 * var(--borderWidth));*/
    border-radius: 10px;
    z-index: 0;
    animation: animatedgradient 4s ease alternate infinite;
    background-size: 300% 300%;
}

.gradient-border:hover a {
    --borderWidth: 10px;
    border: none;
    margin: 5px;
    padding-top: calc(2em - 5px) !important;
    padding-bottom: calc(2em - 5px) !important;
}

/*.gradient-border a{*/
/*    transition:  none !important;*/
/*    border: 5px solid #111;*/
/*}*/

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
