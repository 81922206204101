/* press-start-2p-regular - latin */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url('#{$site-url-prefix}/Fonts/PressStart2P/press-start-2p-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$site-url-prefix}/Fonts/PressStart2P/press-start-2p-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$site-url-prefix}/Fonts/PressStart2P/press-start-2p-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$site-url-prefix}/Fonts/PressStart2P/press-start-2p-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$site-url-prefix}/Fonts/PressStart2P/press-start-2p-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$site-url-prefix}/Fonts/PressStart2P/press-start-2p-v14-latin-regular.svg#PressStart2P') format('svg'); /* Legacy iOS */
}
