.tags .tag a {
    margin-right: 0.5em;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    display: inline-block;
}
.tx-ot-socialmediawall {
    .card-body {font-size: 0.9em;}
    .card-footer {background: transparent;border: 0}
    .ot-social-media-wall-author {color: #031D49;line-height: 1.1em;padding-top: 0.3em;}
    .img-author {height: 3em;width: 3em;margin-right: 1em;border-radius: 50%;margin-bottom: 0.5em;}
    .tags a {color: #00A7CE;}
}
