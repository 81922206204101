aside.left-side {
    position: fixed;
    display: none;
}

.social-media-kontakt {
    position: absolute;
    padding: 1em;
    bottom: 4rem;
    text-align: center;
}
.social-media-kontakt img {
    height: 32px;
    width: 32px;
    margin: 0.5rem 1.8rem;
}

//@media only screen and (min-width: 576px) {
//    aside.left-side {
//        background-color: rgba(203, 50, 131, 0.2);
//        border-right: 4px solid #ccc;
//        display: block;
//    }
//}
@include media-breakpoint-up(lg) {
    aside.left-side {
        //background-color: rgb(190, 0, 100);
        //background: linear-gradient(0deg,#a090ce,#cb3383,#be0064);
        background: var(--bs-primary);
        border-right: 2px solid #ccc;
        display: block;
    }
}

//.content-container {
//    transition: opacity 3s !important;
//}
//.content-container {
//    padding-left: 150px;
//}
.site-navigation li a {
    text-transform: uppercase;
    text-decoration: none;
}

.toggleMainNav {
    position: fixed;
    top: auto;
    bottom: 1.5rem;
    z-index: 10000;
    left: auto;
    right: 1.5rem;
    //background-color: #CB3283;
    //background-color: rgba(0,0,0,0.9);
    opacity: 0.9;
    height: 60px;
    //border: 1px solid #fff;
    //border-radius: 0.5rem;
}

.toggleMainNav button {
    background-color: transparent;
    border: 0;
    display: block;
    margin-left: auto;
    margin-right: 0;
}


// Mobile nav
#main-nav-mobile ul {
    margin: 0;
    padding: 0;
}

#main-nav-mobile > ul {
    margin: 1.5rem 0;
}

#main-nav-mobile > ul > li:last-of-type {
    border-bottom: 1px solid #ccc;
}

#main-nav-mobile ul a {
    text-decoration: none !important;
    font-weight: bold;
    text-transform: uppercase;
}

#main-nav-mobile li {
    margin: 0;
    padding: 0;
    line-height: 2rem;
    border-top: 1px solid #ccc;
    display: block;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    list-style-type: none;
}

// 2. Ebene
#main-nav-mobile > ul > li,
#main-nav-mobile .collap,
#main-nav-mobile .card {
    padding-bottom: 0 !important;
}

#main-nav-mobile li ul li a {
    font-weight: normal;
    text-transform: none;
}

#main-nav-mobile li div {
    border: 0 !important;
    padding: 0 0 0.5rem 0;
}

#main-nav-mobile li ul {
    background-color: #f8f8f8;
    padding: 0;
    margin: 0;
}

#main-nav-mobile li ul li {
    background-color: #ddd;
    padding: 0 1.5em;
    line-height: 2rem !important;
    margin: 0;
}
// Dokytye 4 = shortcut (only mobile nav)
#desktop-navigation .doktype-4 {
    display: none;
}

// Mobile nav
#mobile-navigation {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10000;
    //background-color: rgba(160, 160, 160, 0.7);
    height: 68px;
    width: 68px;

    border-radius: 5px !important;
    background-color: rgb(190, 0, 100);
}
#mobil-nav-logo {
    overflow: hidden;
    width:100%;
    display: block;
    text-align: center;
}

//#hamburger-mobile-nav {
//    margin-top: 5px;
//    margin-left: 2px;
//}

#mobile-navigation button {
    overflow: hidden;
    width: 68px;
    height: 68px;
    border-radius: 0;
    padding-top:2px;
    margin-left: 2px;
}
#mobile-navigation button:focus,
#mobile-navigation button:active {
    outline: none;
    box-shadow: none;
}

// Offcanvas
#offcanvasRight address {
    color: #333;
}
#offcanvasRight .social-media-kontakt {
    position: relative;
    top:0 !important;
    left:0 !important;
    width: 100%;
    margin:0;
    padding:0;
    overflow: hidden;
}
#offcanvasRight .social-media-kontakt div {
    width: 25%;
    float:left;
}
#offcanvasRight .social-media-kontakt .ot-icon {fill: #333;}



//@media only screen and (min-width: 576px) {
@include media-breakpoint-up(lg) {
    #mobile-navigation {
        display: none;
    }
    .toggleMainNav {
        top: 40%;
        left: 2.55rem;
        right: auto;
    }
    .content-container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
