.auto-column-text {
    column-width: 26em;
    column-gap: 3rem;

    h1, h2, h3, h4, h5 {
        margin-bottom: 1.5rem;
        column-span: all;
    }
}

// Punkteliste mit Icon
.tx-ot-faq,
.ot-ctype-ot_pricingtabs .tab-content,
.ot-ctype-ot_text,
.frame-type-text {
    ul {
        padding-left: 0;

        li {
            background: url('#{$site-url-prefix}/Icons/ListIcon.svg') no-repeat left top; /* <-- change `left` & `top` too for extra control */
            padding: 0 0 0 2em;
            line-height: 1.4em;
            list-style: none;
            font-weight: 400;
            margin: 0;
            margin-top: 0px !important;
            margin-bottom: 1em !important;
        }
    }
}
