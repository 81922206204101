@import "Abstract/variables";


h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    a {
        text-decoration: none;
    }
}

.h2 > small {
    color: var(--bs-primary);
    font-weight: normal;
    display: block;
    margin-top: 0.25em;
}

.h2 {
    letter-spacing: 0.01em;
    font-weight: 600;
}

//.content-container {
//    a, a:hover {
//        color: #fff;
//    }
//}

#main b,
#main strong {
    font-weight: 800;
    color: $site-primary-color;
}

body {
    main.container,
    .intro {
        p {
            //font-size: 1.125rem;
            // An anderen Stellen in style.css ist 17px gesetzt
            font-size: 17px;
            font-weight: 300;
            margin-bottom: 1.8em;
        }
    }

    a, a:hover {
        text-decoration: none !important;
        //padding-bottom: 0.1em !important;
    }
}


.btn.btn-light {
    border-color: #fff !important;
    color: #333 !important;
    font-weight: 400;
    padding: 0.5em 2em;
    margin: 1em 0;
}

.footer-1 p {
    font-size: 18px !important;
}
