:root {
    --background-color-1: linear-gradient(145deg, #1e2024, #23272b);
    --shadow-1: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    --color-primary: #be0064;;
    --color-gray: #f6f6f6;


    --font-size-b1: 18px;
    --font-size-b2: 22px;
    --font-size-b3: 14px;
    --line-height-b1: 1.50;
    --line-height-b2: 1.6;
    --line-height-b3: 1.7;
    --h1: 70px;
    --h2: 60px;
    --h3: 50px;
    --h4: 40px;
    --h5: 30px;
    --h6: 20px;
    --transition: 0.4s;
}

.navigation-wrapper ul li a {
    text-decoration: none;
    color: var(--color-gray);
}

.navigation-wrapper {
    margin-left: 10px;
    box-shadow: var(--shadow-1);
}

.navigation-wrapper ul.nav {
    background-color: var(--background-color-1);
    box-shadow: var(--shadow-1);
    justify-content: space-between;
    margin: 0;
    padding: 0;
    border: 0;
}

.navigation-wrapper ul {
    border-radius: 10px;
    align-items: center;
}

.navigation-wrapper ul.nav li {
    flex-basis: 33.33%;
    margin: 0;
}
.navigation-wrapper ul.ot-pricingtabs-tab-count-2 li {
    flex-basis: 50%;
}

.navigation-wrapper .nav-tabs .nav-link.active,
.navigation-wrapper .nav-tabs .nav-item.show .nav-link {
    background-color: transparent;
}

ul li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    margin-top: 10px;
    margin-bottom: 10px;
}

.navigation-wrapper ul.nav li a:hover, .navigation-wrapper ul.nav li a:focus, .navigation-wrapper ul.nav li a.active {
    box-shadow: var(--shadow-1);
    color: var(--color-primary);
}

.navigation-wrapper ul.nav li a {
    text-align: center;
    display: block;
    padding: 30px 10px;
    //border-radius: 6px;
    transition: var(--transition);
}

.navigation-wrapper ul.nav li a:hover, .navigation-wrapper ul.nav li a:focus, .navigation-wrapper ul.nav li a.active {
    box-shadow: var(--shadow-1);
    color: var(--color-primary);
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border: 0 !important;
}


.header-left > span {
    width: 66%;
    display: block;
}

.header-right > span {
    white-space: nowrap
}

.ot-ctype-ot_pricingtabs .tab-content {
    color: #fff;
}

.rn-pricing .ot-video-element {
    -webkit-box-shadow: 0 0 5px 0 rgba(190,0,100,0.32);
    -moz-box-shadow: 0 0 5px 0 rgba(190,0,100,0.32);
    box-shadow: 0 0 5px 0 rgba(190,0,100,0.32);
}

//---------------

.navigation-wrapper .tab-content {
    padding: 0 40px 40px 40px;
}
.rn-pricing .pricing-header {
    display: flex;
    margin-top: 55px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rn-pricing .pricing-header {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 479px) {
    .rn-pricing .pricing-header {
        display: block;
        margin-bottom: 25px;
        margin-top: 30px;
    }
}
@media only screen and (max-width: 479px) {
    .rn-pricing .pricing-header .header-left {
        margin-bottom: 25px;
    }
}
.rn-pricing .pricing-header .header-left h2.title {
    color: #be0064;
    font-size: 27px;
    margin-bottom: 9px;
    font-weight: 700;
}
@media only screen and (max-width: 575px) {
    .rn-pricing .pricing-header .header-left h2.title {
        font-size: 22px;
    }
}
.rn-pricing .pricing-header .header-left span {
    color: #7c838e;
    font-family: var(--font-secondary);
}
.rn-pricing .pricing-header .header-right span {
    //color: var(--color-primary);
    color: #fff;
    background-color: var(--color-primary);
    font-size: 30px;
    font-weight: 600;
    padding: 9px 25px;
    box-shadow: var(--shadow-1);
    border-radius: 6px;
    display: inline-block;
}
.rn-pricing .pricing-body .description {
    font-size: 18px;
    line-height: 30px;
    max-width: 84%;
    font-weight: 400;
    color: #7c838e;
    font-family: var(--font-primary);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-pricing .pricing-body p.description {
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .rn-pricing .pricing-body p.description {
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 575px) {
    .rn-pricing .pricing-body p.description {
        margin-bottom: 30px;
        font-size: 16px;
    }
}
.rn-pricing .pricing-body .check-wrapper {
    justify-content: space-between;
    display: flex;
}
.rn-pricing .pricing-body .check-wrapper .left-area {
    margin-bottom: 30px;
}
.rn-pricing .pricing-body .check-wrapper .check {
    margin-bottom: 15px;
}
.rn-pricing .pricing-body .check-wrapper .check svg {
    margin-top: 2px;
    color: var(--color-lightn);
}
.rn-pricing .pricing-body .check-wrapper .check p {
    margin-left: 5px;
    color: var(--color-lightn);
    font-weight: 400;
    font-size: 18px;
    font-family: var(--font-secondary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rn-pricing .pricing-body .check-wrapper .check p {
        font-size: 16px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-pricing .pricing-body .check-wrapper .check p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 767px) {
    .rn-pricing .pricing-body .check-wrapper .check p {
        font-size: 14px;
    }
}
@media only screen and (max-width: 575px) {
    .rn-pricing .pricing-body .check-wrapper .check p {
        font-size: 13px;
    }
}
@media only screen and (max-width: 479px) {
    .rn-pricing .pricing-body .check-wrapper {
        display: block;
    }
}
.rn-pricing .pricing-footer {
    text-align: center;
    margin-top: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rn-pricing .pricing-footer {
        margin-top: 30px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-pricing .pricing-footer {
        margin-top: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .rn-pricing .pricing-footer {
        margin-top: 15px;
    }
}
.rn-pricing .pricing-footer .rn-btn {
    display: flex;
    margin-bottom: 25px;
    letter-spacing: 1px;
    font-family: var(--font-secondary);
    padding: 20px 0;
}
.rn-pricing .pricing-footer .rn-btn span {
    margin-right: 5px;
    font-size: 16px;
}
.rn-pricing .pricing-footer .rn-btn svg {
    transition: var(--transition);
}
.rn-pricing .pricing-footer .rn-btn:hover svg {
    margin-left: 10px;
}
.rn-pricing .pricing-footer .time-line {
    justify-content: center;
    display: flex;
}
// new begin
:root {
    --shadow-1: 2px 2px 4px rgba(0,0,0,0.6);
}
.ot-ctype-ot_pricingtabs .navigation-wrapper >.nav {
    box-shadow:  4px 4px 4px rgba(0,0,0,0.3);
    background-color: var(--bs-primary) !important;
}
.ot-ctype-ot_pricingtabs .navigation-wrapper > .nav{
    overflow: hidden;
    border-radius: 10px !important;
}
.ot-ctype-ot_pricingtabs .navigation-wrapper >.nav > .nav-item > a.nav-link{
    box-shadow: none;
    font-weight:bold;
    color: #fff;
    border-width: 0 !important;
    border-radius: 10px !important;
}
.ot-ctype-ot_pricingtabs .navigation-wrapper >.nav > .nav-item > a.nav-link:hover{
    background-color: #57606b;
}

.ot-ctype-ot_pricingtabs .navigation-wrapper >.nav > .nav-item > a.nav-link.active {
    background-color: #cb3383;
}
.ot-ctype-ot_pricingtabs >div{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;

}
// new end



@media only screen and (max-width: 479px) {
    .rn-pricing .pricing-footer .time-line {
        display: block !important;
        padding-left: 5px;
    }
}
.rn-pricing .pricing-footer .time-line .single-cmt {
    margin-right: 15px;
    display: flex;
}
@media only screen and (max-width: 479px) {
    .rn-pricing .pricing-footer .time-line .single-cmt:nth-child(2) {
        margin-top: 5px;
    }
}
.rn-pricing .pricing-footer .time-line .single-cmt svg {
    width: 14px;
    margin-right: 7px;
    color: #9ca5b1;
    margin-top: 2px;
}
.rn-pricing .pricing-footer .time-line .single-cmt span {
    font-size: 12px;
    font-weight: 500;
    color: #9ca5b1;
    margin-top: 5px;
    letter-spacing: 1px;
    font-family: var(--font-secondary);
}
@media only screen and (max-width: 479px) {
    .rn-pricing .pricing-footer .time-line .single-cmt span {
        font-size: 12px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-pricing-area .section-title {
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 767px) {
    .rn-pricing-area .section-title {
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 479px) {
    .rn-pricing-area .section-title {
        margin-bottom: 10px;
    }
}
.rn-pricing-area .section-title h2.title {
    margin-bottom: 0;
}
