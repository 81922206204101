.tx-ot-faq button {
    font-weight: bold;
    letter-spacing: 0.02em;
    font-size: 1.1rem
}

.tx-ot-faq :nth-child(even) .accordion-button.collapsed {
    background-color: #be00642b;
    color: var(--bs-primary);
}

.tx-ot-faq ul {
    margin-top: 1em;
}
