.hamburger {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    cursor: pointer;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent
}

.hamburger__line {
    width: 40px;
    height: 2px;
    overflow: hidden;
    position: absolute;
    z-index: 10
}

.hamburger__line-in {
    width: 200px;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0
}

.hamburger__line-in::after, .hamburger__line-in::before {
    width: 60px;
    height: 2px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background-color: #fff
}

.hamburger__line-in::before {
    left: -128px
}

.hamburger__line-in::after {
    left: 0
}

.hamburger__line--01, .hamburger__line--02, .hamburger__line--03 {
    left: 0
}

.hamburger__line--01 {
    top: 10px
}

.hamburger__line--02 {
    top: 22px
}

.hamburger__line--03 {
    top: 34px
}

.hamburger__line--cross01, .hamburger__line--cross02 {
    left: 0
}

.hamburger__line--cross01, .hamburger__line--cross02 {
    top: 22px
}

.hamburger__line--cross01 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.hamburger__line--cross02 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.hamburger__line {
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.hamburger__line-in {
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.hamburger__line-in::after, .hamburger__line-in::before {
    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
}

.hamburger__line-in--cross01, .hamburger__line-in--cross02 {
    -webkit-transform: translateX(-33.3%);
    transform: translateX(-33.3%)
}

.hamburger__line-in--01 {
    -webkit-transition-delay: .2s;
    transition-delay: .2s
}

.hamburger__line-in--02 {
    -webkit-transition-delay: .25s;
    transition-delay: .25s
}

.hamburger__line-in--02::after, .hamburger__line-in--02::before {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms
}

.hamburger__line-in--03 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

.hamburger__line-in--03::after, .hamburger__line-in--03::before {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.hamburger__line-in--cross01 {
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms
}

.hamburger__line-in--cross02 {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms
}

.hamburger__line-in--cross02::after, .hamburger__line-in--cross02::before {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.hamburger.is-opened-navi .hamburger__line-in--01, .hamburger.is-opened-navi .hamburger__line-in--02, .hamburger.is-opened-navi .hamburger__line-in--03 {
    -webkit-transform: translateX(33.3%);
    transform: translateX(33.3%)
}

.hamburger.is-opened-navi .hamburger__line-in--cross01, .hamburger.is-opened-navi .hamburger__line-in--cross02 {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.hamburger.is-opened-navi .hamburger__line-in--01 {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.hamburger.is-opened-navi .hamburger__line-in--02 {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms
}

.hamburger.is-opened-navi .hamburger__line-in--03 {
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
    -webkit-transition-delay: .25s;
    transition-delay: .25s
}

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

.hamburger:hover .hamburger__line-in::after, .hamburger:hover .hamburger__line-in::before {
    -webkit-transform: translateX(200%);
    transform: translateX(200%)
}

.hamburger:hover .hamburger__line-in--01::after, .hamburger:hover .hamburger__line-in--01::before, .hamburger:hover .hamburger__line-in--02::after, .hamburger:hover .hamburger__line-in--02::before, .hamburger:hover .hamburger__line-in--03::after, .hamburger:hover .hamburger__line-in--03::before {
    -webkit-transition-duration: 1s;
    transition-duration: 1s
}

.hamburger:hover .hamburger__line-in--cross01::after, .hamburger:hover .hamburger__line-in--cross01::before, .hamburger:hover .hamburger__line-in--cross02::after, .hamburger:hover .hamburger__line-in--cross02::before {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after, .hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before, .hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after, .hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before {
    -webkit-transition-duration: 1s;
    transition-duration: 1s
}

.hamburger.is-opened-navi:hover .hamburger__line-in--01::after, .hamburger.is-opened-navi:hover .hamburger__line-in--01::before, .hamburger.is-opened-navi:hover .hamburger__line-in--02::after, .hamburger.is-opened-navi:hover .hamburger__line-in--02::before, .hamburger.is-opened-navi:hover .hamburger__line-in--03::after, .hamburger.is-opened-navi:hover .hamburger__line-in--03::before {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}
