.btn-link {
    border: none;
    border-radius: 0;
    padding: 0;
    border-bottom-color: #be0064 !important;
}

.btn-link:hover {
    border-bottom-color: #fff !important;
}
