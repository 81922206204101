// Social Media
$color-facebook: #1877f2;
$color-linkedin: #0073b1;
$color-instagram: #5B51D8;
$color-twitter: #1da1f2;

// Bootstrap Variables
$primary: #be0064;
//$site-primary-color: rgb(190, 0, 100);

$site-primary-color: $primary;
$site-primary-color-light-10: lighten($site-primary-color, 10);
$site-primary-color-light-20: lighten($site-primary-color, 20);

//$card-bg: #222327;

$cl-gray-light: #bcc0c4;
$cl-gray-dark: #57606b;



$body-color: #57606b;
//$accordion-btn-color: #fff;
//$accordion-bg: #222327;

$background-page: #111215;

$input-color: $background-page;

$input-plaintext-color: $background-page;

$form-label-color: $background-page;

$text-muted: #d1d8df;
$figure-caption-color: #757f88;

$border-color-translucent: $primary;

    //$theme-colors: (
//    "primary":    $blue
//    //"secondary":  $secondary,
//    //"success":    $success,
//    //"info":       $info,
//    //"warning":    $warning,
//    //"danger":     $danger,
//    //"light":      $light,
//    //"dark":       $dark
//);

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1378px,
);

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$site-url-prefix: '../Assets/Website';
