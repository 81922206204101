/* montserrat-700italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    src: local(''),
    url('#{$site-url-prefix}/Fonts/Montserrat/montserrat-v25-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$site-url-prefix}/Fonts/Montserrat/montserrat-v25-latin-700italic.woff') format('woff'), /* Modern Browsers */
}
