@import "ContentElements/ot-text";
@import "ContentElements/ot-pricingtabs";
@import "ContentElements/ot-fancycarousel";
@import "ContentElements/ot-socialmediacards";


.ot-ce {
    width: 100%;
    clear: both;
}
.ot-ce-margin-bottom {
    margin-bottom: 1.5rem;
}
