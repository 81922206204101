@import "@fancyapps/ui/dist/fancybox/fancybox.css";
@import "@fancyapps/ui/dist/carousel/carousel.css";
@import "@fancyapps/ui/dist/carousel/carousel.autoplay.css";


.carousel__button.is-prev,
.carousel__button.is-next {
    opacity: 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    border: 1px solid #eee;
    border-radius: 50%;
}

.carousel__button.is-next {
    right: 0;
}

.carousel__button.is-prev {
    left: 0;
}

.carousel:hover .carousel__button {
    opacity: 1;
}

.carousel:hover .carousel__button.is-next {
    right: 5rem;
}
.carousel:hover .carousel__button.is-prev {
    left: 5rem;
}

.carousel__dots {
    right: 5rem;
    left: auto;
}
@include media-breakpoint-down(md) {
    .carousel:hover .carousel__button.is-next {
        right: 1em;
    }
    .carousel:hover .carousel__button.is-prev {
        left: 1em;
    }
}
@include media-breakpoint-between(md, xl) {
    .carousel:hover .carousel__button.is-next {
        right: 2rem;
    }
    .carousel:hover .carousel__button.is-prev {
        left: 2rem;
    }
}
