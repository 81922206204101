@import "../Abstract/variables";

///*
//
//@Author: Themezinho
//@URL: http://www.themezinho.net
//
//This file contains the styling for the actual theme, this
//is the file you need to edit to change the look of the
//theme.
//
//
//	// Table of contents //
//
//		01. GOOGLE FONTS
//		02. BODY
//		03. HTML TAGS
//		04. CUSTOM TAGS
//		05. SECTIONS
//		06. CONTENT
//		07. ODOMETER
//		08. EQUALIZER
//		09. REVEAL EFFECT
//		10. PAGE TRANSITION
//		11. PRELOADER
//		12. PAGE LOADED
//		13. TEXT ROTATER
//		14. PERSPECTIVE
//		15. SOCIAL BAR
//		16. HEADER
//		17. DROPDOWN
//		18. SLIDER
//		19. SLIDER FRACTION
//		20. SLIDER BUTTONS
//		21. SLIDER CONTENT
//		22. SLIDER LINKS
//		23. INT HERO
//		24. ABOUT STUDIO
//		25. WORKS
//		26. WORKS FILTER
//		27. WORKS FIGURE
//		28. ICON FEATURES
//		29. TEAM MEMBERS
//		30. CLIENTS
//		31. SIDE IMAGE CONTENT
//		32. AWARDS
//		33. JOURNAL
//		34. CONTACT
//		35. FOOTER
//		36. SCROLL DOWN
//		37. RESPONSIVE MEDIUM FIXES
//		38. RESPONSIVE TABLET FIXES
//		39. RESPONSIVE MOBILE FIXES
//
//
//
//*/
/* GOOGLE FONTS */

// todo Prüfen, ob wir den Font verwenden
//@import url("https://fonts.googleapis.com/css?family=Fjalla+One%7CPoppins:300,400,600,800&display=swap");
/* BODY */
* {
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

/* HTML ELEMENTS */
img {
  max-width: 100%;
}

/* LINKS */
a {
  color: #222327;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
a:hover {
  text-decoration: underline;
  color: #222327;
}

/* CUSTOM CLASSES */
.overflow {
  overflow: hidden;
}

/* CUSTOM CONTAINER */
@media (min-width: 1300px) {
  .container {
    max-width: 1340px;
  }
}
/* SECTIONS */
main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}

section {
  position: relative;
}

aside {
  position: relative;
}

/* FORM ELEMENTS */
input[type=text] {
  max-width: 100%;
  height: 58px;
  border: 1px solid #eee;
  padding: 0 20px;
}

input[type=email] {
  max-width: 100%;
  height: 58px;
  border: 1px solid #eee;
  padding: 0 20px;
}

input[type=search] {
  max-width: 100%;
  height: 58px;
  border: 1px solid #eee;
  padding: 0 20px;
}

input[type=password] {
  max-width: 100%;
  height: 58px;
  border: 1px solid #eee;
  padding: 0 20px;
}

input[type=submit] {
  height: 58px;
  border: none;
  background: #222327;
  color: #fff;
  padding: 0 30px;
  font-weight: 600;
  font-size: 14px;
}

button[type=submit] {
  height: 58px;
  border: none;
  background: #222327;
  color: #fff;
  padding: 0 30px;
  font-weight: 600;
  font-size: 14px;
}

textarea {
  max-width: 100%;
  height: 140px;
  border: 1px solid #eee;
  padding: 20px;
}

/* EQUALIZER */
.equalizer {
  width: 26px;
  height: 30px;
  cursor: pointer;
  position: relative;
}

.equalizer span {
  background: #fff;
  width: 2px;
  height: 0px;
  bottom: 0;
  left: 0%;
  display: block;
  position: absolute;
  transition: height 0.2s linear;
}

.equalizer span:nth-child(1) {
  margin-left: 8px;
}

.equalizer span:nth-child(2) {
  margin-left: 16px;
}

.equalizer span:nth-child(4) {
  margin-left: 24px;
}

.equalizer.paused span {
  height: 3px !important;
}

/* SPLITTING */
.splitting .word, .splitting .char {
  display: inline-block;
}

.splitting .char {
  position: relative;
}

.animated .char {
  display: inline-block;
  animation: texteffect 0.6s cubic-bezier(0.5, 0, 0.5, 1) both;
  animation-delay: calc(10ms * var(--char-index));
}

/* ODOMETER */
.odometer.odometer-auto-theme, .odometer.odometer-theme-car {
  padding: 0;
}

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-car .odometer-digit {
  padding: 0;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-car .odometer-digit .odometer-value {
  text-indent: -5px;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-car .odometer-digit .odometer-value {
  text-indent: 0;
}

/* PAGINATION */
.pagination {
  width: 100%;
  float: left;
  margin: 50px 0;
}
.pagination .page-item {
  display: inline-block;
  margin-right: 10px;
}
.pagination .page-item .page-link {
  font-weight: 600;
  border-radius: 0 !important;
  font-size: 13px;
  color: #222327;
  line-height: 1;
  padding: 15px 30px;
}

/* REVEAL EFFECT */
.reveal-effect {
  float: left;
  position: relative;
}

.reveal-effect > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
}

.reveal-effect > * {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.reveal-effect {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.reveal-effect {
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-effect.animated {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.reveal-effect.animated * {
  -webkit-animation-name: show-img-1;
  animation-name: show-img-1;
}

.reveal-effect:after {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.reveal-effect:after {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0.05, 0);
  transform: scale(0.05, 0);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect.masker:after {
  background-color: #161619;
}

.reveal-effect.animated:after {
  -webkit-animation-name: slide-bg-2;
  animation-name: slide-bg-2;
}

/* SWIPER PAGINATION */
.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  transform: scale(0.4);
  background: #fff;
  opacity: 1;
  border-radius: 50%;
}

.swiper-pagination .swiper-pagination-bullet:hover {
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  -webkit-transform: scale(1);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  border: 2px solid #fff;
  background: none;
}

.swiper-pagination .swiper-pagination-bullet-active {
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  -webkit-transform: scale(1);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  border: 2px solid #fff;
  background: none;
}

/* PRELOADER */
.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  background: #75dab4;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  overflow: hidden;
  transition-delay: 0.6s;
}
.preloader * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.preloader .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #1c1c1c;
  transition-delay: 0.3s;
}
.preloader .inner {
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 2;
  margin: 0 auto;
}
.preloader .inner span {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}
.preloader .inner figure {
  display: block;
  margin-bottom: 20px;
  transition-delay: 0.1s;
}
.preloader .inner figure img {
  height: 40px;
  transform: translateX(-7px);
}

/* PAGE LOADED */
.page-loaded .preloader {
  left: -100%;
}

.page-loaded .preloader .inner figure {
  opacity: 0;
  transform: scale(1.5);
}

.page-loaded .preloader .inner span {
  transform: translateY(20px);
  opacity: 0;
}

.page-loaded .preloader .layer {
  left: -100%;
}

.page-loaded .left-side {
  left: 0;
  opacity: 1;
}

.page-loaded .slider {
  filter: blur(0);
  transform: scale(1);
}

.page-loaded .page-header {
  filter: blur(0);
  transform: scale(1);
}

.page-loaded .all-cases-link {
  right: 30px;
}

/* PAGE TRANSTION */
.page-transition {
  width: 100%;
  height: 100%;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 13;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  background: $site-primary-color-light-10; // #75dab4;
}
.page-transition .layer {
  width: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #1c1c1c;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.3s;
}
.page-transition.active {
  opacity: 1;
  visibility: visible;
  right: 0;
}
.page-transition.active .layer {
  width: 100%;
}

/* SITE NAVIGATION */
.site-navigation {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  background: $site-primary-color-light-10; // #75dab4;
  padding-left: 120px;
}
.site-navigation .layer {
  width: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #161619;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.3s;
}
.site-navigation .inner {
  position: relative;
  z-index: 2;
  padding-left: 100px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transition-delay: 0.7s;
  opacity: 0;
}
.site-navigation .inner ul {
  margin: 0;
  padding: 0;
  animation-duration: 1s;
}
.site-navigation .inner ul li {
  display: block;
  margin: 5px 0;
  padding: 5px 0;
}
.site-navigation .inner ul li ul {
  margin-top: 0;
  margin-bottom: 10px;
  display: none;
}
.site-navigation .inner ul li ul li {
  display: block;
  margin: 0;
}
.site-navigation .inner ul li ul li a {
  font-size: 26px;
}
.site-navigation .inner ul li i {
  margin-left: 20px;
  font-size: 30px;
  color: #fff;
  display: inline-block;
  line-height: 1;
  transform: translateY(-5px);
  cursor: pointer;
}
.site-navigation .inner ul li small {
  width: 100%;
  display: block;
  color: #fff;
  opacity: 0.7;
  letter-spacing: 0.5px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  transform: translateY(-15px);
}
.site-navigation .inner ul li a {
  font-size: 50px;
  display: inline-block;
  font-weight: 800;
  color: #fff;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.site-navigation .inner ul li a:hover {
  color: $site-primary-color; // #75dab4;
  text-decoration: none;
}
.site-navigation.active {
  opacity: 1;
  visibility: visible;
  right: 0;
}
.site-navigation.active .layer {
  width: 100%;
}
.site-navigation.active .inner {
  opacity: 1;
}

/* SECTION TITLES */
.section-title {
  font-family: "Fjalla One", sans-serif;
  margin-bottom: 80px;
  line-height: 1.4;
  font-size: 4vw;
  letter-spacing: 2px;
}

/* SOCIAL MEDIA */
.social-media {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 3;
  background: $site-primary-color; // #75dab4;
  opacity: 0;
  visibility: hidden;
  //-webkit-transition: all 0.3s ease;
  //-moz-transition: all 0.3s ease;
  //transition: all 0.3s ease;
  //transition-duration: 500ms;
  //-webkit-transition-duration: 500ms;
  //transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  //-webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.social-media .layer {
  width: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #161619;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.3s;
}
.social-media .inner {
  position: relative;
  z-index: 2;
  padding-left: 100px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transition-delay: 0.7s;
  opacity: 0;
  text-align: center;
  width: 100%;
}
.social-media .inner h5 {
  font-size: 3vw;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}
.social-media .inner ul {
  display: block;
  margin: 0;
  padding: 0;
}
.social-media .inner ul li {
  font-size: 2vw;
  display: inline-block;
  margin: 0 40px;
  padding: 0;
  list-style: none;
}
.social-media .inner ul li a {
  color: #fff;
}
.social-media .inner ul li a:hover {
  color: $site-primary-color; // #75dab4;
  text-decoration: none;
}
.social-media.active {
  opacity: 1;
  visibility: visible;
  right: 0;
}
.social-media.active .layer {
  width: 100%;
}
.social-media.active .inner {
  opacity: 1;
}

/* ALL CASES */
.all-cases {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 3;
  background: $site-primary-color; // #75dab4;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.all-cases .layer {
  width: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #161619;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.3s;
}
.all-cases .inner {
  position: relative;
  z-index: 2;
  padding-left: 220px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  transition-delay: 0.7s;
  opacity: 0;
  width: 100%;
}
.all-cases .inner ul {
  display: block;
  max-height: 500px;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}
.all-cases .inner ul li {
  font-size: 8vw;
  font-weight: 800;
  margin: 0;
  padding: 0;
  list-style: none;
}
.all-cases .inner ul li a {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}
.all-cases .inner ul li a:hover {
  color: #fff;
  text-decoration: none;
}
.all-cases.active {
  opacity: 1;
  visibility: visible;
  right: 0;
}
.all-cases.active .layer {
  width: 100%;
}
.all-cases.active .inner {
  opacity: 1;
}

/* ALL CASES LINK */
.all-cases-link {
  position: absolute;
  right: -100%;
  top: 30px;
  z-index: 5;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 1s;
}
.all-cases-link span {
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  margin-right: 10px;
}
.all-cases-link b {
  width: 40px;
  height: 40px;
  line-height: 44px;
  font-size: 25px;
  font-weight: 300;
  display: inline-block;
  background: #fff;
  color: #222327;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.all-cases-link b:hover {
  background: $site-primary-color; // #75dab4;
  text-decoration: none;
  color: #fff;
}

/* LEFT SIDE */
.left-side {
  width: 120px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -100%;
  top: 0;
  z-index: 10;
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px 0;
  //-webkit-transition: all 0.3s ease;
  //-moz-transition: all 0.3s ease;
  //transition: all 0.3s ease;
  //transition-duration: 500ms;
  //-webkit-transition-duration: 500ms;
  //transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  //-webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  //transition-delay: 1s;
  opacity: 0;
}
.left-side .logo {
  display: inline-block;
  margin-bottom: auto;
}
.left-side .logo img {
  width: 50px;
}
.left-side .hamburger {
  margin: auto;
}
.left-side .follow-us {
  width: 100px;
  font-family: "Fjalla One", sans-serif;
  color: #fff;
  text-align: center;
  transform: rotate(-90deg);
  margin: auto;
  cursor: pointer;
}
.left-side .equalizer {
  margin: auto;
  margin-bottom: 0;
}

/* SLIDER */
.slider {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transform: scale(1.2);
  filter: blur(20px);
  transition-delay: 0.8s;
}
.slider .swiper-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.slider .swiper-container .swiper-slide {
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.slider .swiper-container .swiper-slide video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}
.slider .gallery-top {
  height: 100%;
  width: 100%;
  background: #222327;
}
.slider .gallery-thumbs {
  width: calc(100% - 240px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 120px;
  padding-bottom: 30px;
  z-index: 9;
  position: absolute;
  bottom: 30%;
  right: 0;
  height: auto;
  color: #fff;
}
.slider .gallery-thumbs .swiper-slide {
  width: 90%;
  height: 100%;
  opacity: 0.4;
  text-align: center;
  filter: blur(3px);
}
.slider .gallery-thumbs .swiper-slide span {
  margin-left: 50%;
  transform: translateX(-50%);
  float: left;
  font-size: 3vw;
  line-height: 5vw;
  font-weight: 800;
}
.slider .gallery-thumbs .swiper-slide a {
  display: none;
  font-size: 13px;
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  padding-bottom: 5px;
  position: relative;
}
.slider .gallery-thumbs .swiper-slide a:before {
  content: "";
  width: 20px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.slider .gallery-thumbs .swiper-slide a:hover {
  text-decoration: none;
}
.slider .gallery-thumbs .swiper-slide a:hover:before {
  width: 100%;
}
.slider .gallery-thumbs .swiper-slide-active {
  width: 100%;
  opacity: 1;
  transform: scale(1.4);
  filter: blur(0px);
}
.slider .gallery-thumbs .swiper-slide-active span {
  font-size: 4vw;
}
.slider .gallery-thumbs .swiper-slide-active a {
  display: inline-block;
}
.slider .swiper-button-prev {
  height: auto;
  position: absolute;
  left: auto;
  top: auto;
  right: 120px;
  bottom: 30px;
  font-family: "Fjalla One", sans-serif;
  color: #fff;
  background: none;
}
.slider .swiper-button-next {
  height: auto;
  position: absolute;
  left: auto;
  top: auto;
  right: 40px;
  bottom: 30px;
  font-family: "Fjalla One", sans-serif;
  color: #fff;
  background: none;
}
.slider .slide-progress {
  width: 220px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 50%;
  bottom: 30px;
  margin-left: -110px;
  z-index: 3;
  text-align: center;
}
.slider .slide-progress span {
  display: inline-block;
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  font-size: 14px;
}
.slider .slide-progress .swiper-pagination {
  width: 160px;
  height: 2px;
  margin: auto 10px;
  display: inline-block;
  position: static;
  background: rgba(255, 255, 255, 0.3);
}
.slider .slide-progress .swiper-pagination .swiper-pagination-progressbar-fill {
  background: #fff;
}

/* VIDEO HERO */
.video-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.video-hero .video-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background: #222327;
}
.video-hero .video-bg video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}
.video-hero .inner {
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
  z-index: 2;
}
.video-hero .inner span {
  width: 100%;
  float: left;
  font-size: 8vw;
  line-height: 5vw;
  font-weight: 800;
  color: #fff;
  margin-bottom: 30px;
}
.video-hero .inner a {
  font-size: 19px;
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 2px solid #fff;
  padding-bottom: 4px;
}
.video-hero .inner a:hover {
  text-decoration: none;
  color: $site-primary-color; // #75dab4;
}

/* CAROUSEL HERO */
.carousel-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.carousel-hero .carousel-slider {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.carousel-hero .carousel-slider .swiper-slide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  background-size: cover;
  background-position: center;
}
.carousel-hero .carousel-slider .swiper-slide.swiper-slide-active {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.carousel-hero .carousel-slider .swiper-slide.swiper-slide-active .inner {
  opacity: 1;
}
.carousel-hero .carousel-slider .swiper-slide.swiper-slide-active:after {
  opacity: 0.4;
}
.carousel-hero .carousel-slider .swiper-slide:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #222327;
  opacity: 0.7;
}
.carousel-hero .carousel-slider .swiper-slide .inner {
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
  opacity: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.carousel-hero .carousel-slider .swiper-slide .inner h2 {
  width: 100%;
  float: left;
  font-size: 4vw;
  line-height: 5vw;
  font-weight: 800;
  color: #fff;
  margin-bottom: 10px;
}
.carousel-hero .carousel-slider .swiper-slide .inner a {
  font-size: 19px;
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 2px solid #fff;
  padding-bottom: 4px;
}
.carousel-hero .carousel-slider .swiper-slide .inner a:hover {
  text-decoration: none;
  color: $site-primary-color; // #75dab4;
}
.carousel-hero .swiper-button-prev {
  height: auto;
  position: absolute;
  left: auto;
  top: auto;
  right: 120px;
  bottom: 30px;
  font-family: "Fjalla One", sans-serif;
  color: #fff;
  background: none;
}
.carousel-hero .swiper-button-next {
  height: auto;
  position: absolute;
  left: auto;
  top: auto;
  right: 40px;
  bottom: 30px;
  font-family: "Fjalla One", sans-serif;
  color: #fff;
  background: none;
}
.carousel-hero .slide-progress {
  width: 220px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 50%;
  bottom: 30px;
  margin-left: -110px;
  z-index: 3;
  text-align: center;
}
.carousel-hero .slide-progress span {
  display: inline-block;
  color: #fff;
  font-family: "Fjalla One", sans-serif;
  font-size: 14px;
}
.carousel-hero .slide-progress .swiper-pagination {
  width: 160px;
  height: 2px;
  margin: auto 10px;
  display: inline-block;
  position: static;
  background: rgba(255, 255, 255, 0.3);
}
.carousel-hero .slide-progress .swiper-pagination .swiper-pagination-progressbar-fill {
  background: #fff;
}

/* PAGE HEADER */
.page-header {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  background: #161619;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transform: scale(1.2);
  filter: blur(20px);
  transition-delay: 0.8s;
}
.page-header .video-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.page-header .video-bg video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
}
.page-header .inner {
  width: 100%;
  padding-left: 220px;
  position: relative;
  z-index: 2;
}
.page-header .inner h1 {
  color: #fff;
  font-weight: 800;
  font-size: 10vw;
  letter-spacing: -3px;
}
.page-header .inner p {
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

/* INTRO */
.intro {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  color: #fff;
  background: #57606b;
}
.intro .col-lg-7 {
  padding-left: 10%;
}
.intro h6 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 19px;
}
.intro h6:after {
  content: "";
  width: 70px;
  height: 1px;
  background: #fff;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 7px;
}
.intro b {
  font-size: 120px;
  line-height: 1;
  font-family: "Fjalla One", sans-serif;
  float: left;
  font-weight: 400;
  margin-right: 20px;
}
.intro h4 {
  font-weight: 600;
}
//.intro p {
//  font-size: 20px;
//  font-weight: 300;
//  margin-bottom: 35px;
//}
main.container small,
.intro small {
  display: block;
  opacity: 0.7;
  margin-bottom: 50px;
}
//main.container a,
//.intro a {
  //color: #fff;
  //font-size: 17px;
  //border-bottom: 1px solid $site-primary-color; // #75dab4;
  //padding-bottom: 5px;
//}
//main.container a,
//.intro a:hover {
//  border-color: #fff;
//}

/* INTRO IMAGE */
.intro-image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 150px;
  position: relative;
}
.intro-image:before {
  content: "";
  width: 100%;
  height: 100px;
  background: #161619;
  position: absolute;
  left: 0;
  top: 0;
}
.intro-image.light:before {
  background: #fff;
}
.intro-image .office-slider {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #222327;
  position: relative;
  overflow: hidden;
}
.intro-image figure {
  width: 100%;
  display: block;
  margin: 0;
  position: relative;
}
.intro-image figure img {
  width: 100%;
  opacity: 0.7;
}
.intro-image figure figcaption {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px;
  line-height: 1;
}
.intro-image figure figcaption h6 {
  font-size: 13px;
  color: #fff;
  font-weight: 600;
}

/* PROCESS */
.process {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 150px;
  color: #fff;
  text-align: center;
}
.process .odometer {
  font-family: "Fjalla One", sans-serif;
  font-size: 5vw;
  line-height: 1;
}
.process .symbol {
  width: 100%;
  display: block;
  font-size: 12px;
}
.process small {
  width: 80%;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 30px;
  margin: 20px 10%;
  font-weight: 600;
}

/* ICON CONTENT BLOCK */
.icon-content-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  color: #fff;
  background: #222327;
}
.icon-content-block .container {
  position: relative;
  z-index: 2;
}
.icon-content-block .content-block {
  width: 100%;
  display: block;
  margin: 0;
  padding: 50px 30px;
  position: relative;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.icon-content-block .content-block.selected {
  background: #161619;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
}
.icon-content-block .content-block.selected:before {
  content: "";
  width: 50%;
  height: 50%;
  position: absolute;
  right: -25px;
  bottom: -30px;
  z-index: -1;
  background: url("#{$site-url-prefix}/Images/dot-pattern.png") right;
  background-size: 10px;
}
.icon-content-block .content-block figure {
  width: 100%;
  display: block;
  margin-bottom: 40px;
}
.icon-content-block .content-block figure img {
  height: 75px;
}
.icon-content-block .content-block h6 {
  display: block;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
.icon-content-block .content-block ul {
  margin: 0;
  padding: 0;
}
.icon-content-block .content-block ul li {
  padding: 3px 0;
  opacity: 0.7;
  list-style: none;
  font-weight: 300;
}

/* TEXT CONTENT BLOCK */
.text-content-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #fff;
}
.text-content-block .col-md-4 {
  margin: 20px 0;
}
.text-content-block .section-title {
  margin-bottom: 30px;
}
.text-content-block h5 {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  padding-right: 20%;
  margin-bottom: 70px;
}
.text-content-block small {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: -20px;
  opacity: 0.1;
  display: block;
}
.text-content-block h6 {
  font-size: 20px;
  font-family: "Fjalla One", sans-serif;
}
.text-content-block p {
  padding-right: 20%;
  margin-bottom: 0;
  line-height: 1.5;
}

/* TESTIMONIALS */
.testimonials {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 150px;
  color: #fff;
  background: #161619;
  position: relative;
}
.testimonials:before {
  content: "";
  width: 100%;
  height: 50px;
  background: #222327;
  position: absolute;
  left: 0;
  top: 0;
}
.testimonials .section-title {
  margin-bottom: 0;
}
.testimonials .testimonials-slider {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
}
.testimonials .swiper-pagination {
  width: 100px;
  right: 80px;
  left: auto;
  bottom: 115px;
  text-align: right;
}
.testimonials .swiper-pagination-bullet {
  background: #222327;
}
.testimonials .swiper-pagination-bullet:hover {
  border-color: #222327;
}
.testimonials .swiper-pagination-bullet-active {
  border-color: #222327;
  background: none;
}
.testimonials .testimonial {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 100px 80px;
  background: $site-primary-color; // #75dab4;
  color: #222327;
}
.testimonials .testimonial blockquote {
  font-size: 21px;
  line-height: 1.7;
  font-weight: 300;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.testimonials .testimonial .reviewer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.testimonials .testimonial .reviewer img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  float: left;
}
.testimonials .testimonial .reviewer .reviewer-infos {
  width: calc(100% - 65px);
  float: left;
}
.testimonials .testimonial .reviewer h6 {
  display: block;
  font-weight: 600;
}
.testimonials .testimonial .reviewer small {
  display: block;
}

/* TEAM */
.team {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  background: #161619;
  position: relative;
  z-index: 2;
}
.team:before {
  content: "";
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
}
.team .reveal-effect.masker:after {
  background: #222327;
}
.team h5 {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
}
.team figure {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: -100px;
}
.team figure:hover ul {
  opacity: 1;
  visibility: visible;
}
.team figure img {
  width: 100%;
}
.team figure figcaption {
  display: block;
  color: #222327;
  margin-top: 20px;
}
.team figure figcaption h6 {
  display: block;
  font-weight: 600;
}
.team figure figcaption small {
  display: block;
  opacity: 0.5;
}
.team figure ul {
  width: 80%;
  display: flex;
  margin: 10%;
  padding: 0;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 60px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.team figure ul li {
  flex: 1;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  text-align: center;
  border-right: 1px solid #eee;
}
.team figure ul li:last-child {
  border-right: 0;
}
.team figure ul li a {
  color: #222327;
  font-size: 13px;
  padding: 5px;
}
.team figure ul li a:hover {
  color: $site-primary-color; // #75dab4;
}

/* WORKS */
.works {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #222327;
  color: #fff;
}
.works ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -60px 0;
  padding: 0;
  position: relative;
  z-index: 2;
}
.works ul li {
  width: 33.33333%;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 60px;
}
.works ul li:nth-child(2n+2) {
  margin-top: 100px;
}
.works ul li:nth-child(3n+3) {
  margin-top: 40px;
}
.works ul li figure {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  background: #222327;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.works ul li figure:hover {
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
}
.works ul li figure:hover:before {
  opacity: 1;
}
.works ul li figure:before {
  content: "";
  width: 90%;
  height: 30%;
  position: absolute;
  right: -25px;
  bottom: -30px;
  z-index: -1;
    background: url("#{$site-url-prefix}/Images/dot-pattern.png") right;
  background-size: 10px;
  opacity: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.works ul li figure a {
  width: 100%;
  background: #222327;
  display: block;
  position: relative;
  z-index: 2;
}
.works ul li figure a img {
  width: 100%;
}
.works ul li h3 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 26px;
  letter-spacing: -1px;
}
.works ul li small {
  display: block;
  font-family: "Fjalla One", sans-serif;
  opacity: 0.7;
}

/* CASE STUDY */
.case-study {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #fff;
}
.case-study h6 {
  font-weight: 600;
  margin-top: 30px;
}
.case-study h5 {
  font-family: "Fjalla One", sans-serif;
  font-size: 5vw;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;
}
.case-study p {
  margin-bottom: 20px;
  line-height: 1.5;
}
.case-study figure {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.case-study figure img {
  width: 100%;
}
.case-study figure video {
  width: 100%;
}
.case-study .container-fluid {
  margin-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
}

/* BLOG */
.blog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 100px 0;
  background: #fff;
}
.blog .col-lg-9 {
  padding-right: 60px;
}
.blog .sidebar {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
}
.blog .sidebar .widget {
  width: 100%;
  display: block;
  border-left: 1px solid #eee;
  margin-bottom: 40px;
  padding-left: 30px;
  padding-bottom: 10px;
}
.blog .sidebar .widget .title {
  display: block;
  font-weight: 800;
  font-size: 19px;
  padding: 15px 0;
  position: relative;
}
.blog .sidebar .widget .title:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #222327;
  position: absolute;
  left: -31px;
  top: 0;
}
.blog .sidebar .widget p {
  margin: 0;
}
.blog .sidebar .widget form {
  width: 100%;
}
.blog .sidebar .widget form input[type=text] {
  width: 100%;
  margin-bottom: 10px;
}
.blog .sidebar .widget .categories {
  width: 100%;
  margin: 0;
  padding: 0;
}
.blog .sidebar .widget .categories li {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
  padding: 0;
  list-style: none;
}
.blog .sidebar .widget .categories li span {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #ccc;
  color: #fff;
  margin-right: 15px;
  margin-left: 0;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}
.blog .sidebar .widget .categories li a {
  color: #222327;
  margin-left: 0;
  font-weight: 600;
}
.blog .sidebar .widget .tags {
  width: 100%;
  display: block;
  margin-bottom: 0;
  padding: 0;
}
.blog .sidebar .widget .tags li {
  display: inline-block;
  list-style: none;
  margin-bottom: 5px;
}
.blog .sidebar .widget .tags li a {
  font-weight: 600;
  font-size: 11px;
  background: #222327;
  color: #fff;
  line-height: 1;
  padding: 3px 6px;
}
.blog .sidebar .widget .tags li a:hover {
  color: $site-primary-color; // #75dab4;
  text-decoration: none;
}
.blog .sidebar .widget .mini-works {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.blog .sidebar .widget .mini-works li {
  width: 33.3333%;
  display: inline-block;
  margin: 0;
  padding: 4px;
  list-style: none;
}
.blog .post {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 50px 0;
}
.blog .post.single {
  position: relative;
}
.blog .post.single .post-image {
  width: 100%;
  margin-bottom: 50px;
}
.blog .post.single .post-content {
  width: 100%;
  padding: 0 60px;
}
.blog .post.single .post-content .post-title {
  font-size: 4vw;
  line-height: 1.4;
}
.blog .post:nth-child(even) {
  position: relative;
}
.blog .post:nth-child(even) .post-image {
  order: 2;
}
.blog .post:nth-child(even) .post-image:before {
  right: auto;
  left: -25px;
}
.blog .post:nth-child(even) .post-content {
  order: 1;
  padding-right: 60px;
  padding-left: 0;
  text-align: right;
}
.blog .post .post-image {
  width: 45%;
  margin: 0;
  position: relative;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.1);
}
.blog .post .post-image img {
  position: relative;
  width: 100%;
}
.blog .post .post-image:before {
  content: "";
  width: 90%;
  height: 60%;
  position: absolute;
  right: -25px;
  bottom: -30px;
  z-index: 0;
    background: url("#{$site-url-prefix}/Images/dot-pattern.png") right;
  background-size: 10px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  opacity: 0.2;
}
.blog .post .post-content {
  width: 55%;
  padding-left: 60px;
}
.blog .post .post-content h5 {
  font-weight: 600;
  margin-top: 30px;
}
.blog .post .post-content ul {
  margin-bottom: 30px;
  padding-left: 20px;
}
.blog .post .post-content ul li {
  margin: 0;
}
.blog .post .post-content .social-share {
  width: 100%;
  float: left;
  padding: 0;
  margin-bottom: 40px;
  text-align: center;
}
.blog .post .post-content .social-share li {
  float: left;
  margin-right: 5px;
  padding: 0;
  list-style: none;
}
.blog .post .post-content .social-share li.facebook a {
  background: #475993;
}
.blog .post .post-content .social-share li.twitter a {
  background: #76a9ea;
}
.blog .post .post-content .social-share li.google-plus a {
  background: #f34a38;
}
.blog .post .post-content .social-share li.linkedin a {
  background: #0077b7;
}
.blog .post .post-content .social-share li.youtube a {
  background: #f61c0d;
}
.blog .post .post-content .social-share li a {
  width: 44px;
  height: 44px;
  line-height: 44px;
  float: left;
  background: #222327;
  color: #fff;
  border: none;
  border-radius: 0;
}
.blog .post .post-content .image-left {
  width: 40%;
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.blog .post .post-content .image-full {
  width: 100%;
  display: block;
  margin: 30px 0;
}
.blog .post .post-content .post-date {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  opacity: 0.5;
}
.blog .post .post-content .post-title {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-size: 3vw;
  font-weight: 800;
}
.blog .post .post-content .post-title a {
  color: #161619;
}
.blog .post .post-content .post-title a:hover {
  color: $site-primary-color; // #75dab4;
  text-decoration: none;
}
.blog .post .post-content .post-author {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
.blog .post .post-content .post-author img {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
}
.blog .post .post-content .post-author span {
  display: inline-block;
  color: #A7A7A7;
}
.blog .post .post-content .post-author span a {
  color: #222327;
  font-size: 15px;
}
.blog .post .post-content .post-author span a:hover {
  color: #222327;
}
.blog .post .post-content .post-categories {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  padding: 0;
}
.blog .post .post-content .post-categories li {
  display: inline-block;
  list-style: none;
}
.blog .post .post-content .post-categories li a {
  font-weight: 600;
  font-size: 11px;
  background: #222327;
  color: #fff;
  line-height: 1;
  padding: 3px 6px;
}
.blog .post .post-content .post-categories li a:hover {
  color: $site-primary-color; // #75dab4;
  text-decoration: none;
}
.blog .post .post-content .post-link {
  font-family: "Fjalla One", sans-serif;
  border-bottom: 2px solid #222327;
  padding-bottom: 5px;
}
.blog .post .post-content .post-link:hover {
  color: $site-primary-color; // #75dab4;
  text-decoration: none;
}

/* CLIENTS */
.clients {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #fff;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.3);
}
.clients ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.clients ul li {
  width: 33.33333%;
  margin: 0;
  padding: 40px;
  list-style: none;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
}

/* CONTACT */
.contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: #fff;
}
.contact h6 {
  font-weight: 600;
  margin-top: 30px;
}
.contact address {
  display: block;
}
.contact address p {
  margin: 0;
}
.contact address a {
  text-decoration: underline;
}
.contact .contact-form {
  width: 100%;
  display: block;
}
.contact .contact-form #contact {
  display: block;
}
.contact .contact-form #contact .form-group {
  display: block;
  position: relative;
  margin-bottom: 35px;
}
.contact .contact-form #contact .form-group span {
  width: 100%;
  line-height: 58px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 20px;
  z-index: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.contact .contact-form #contact .form-group span.label-up {
  font-size: 13px;
  line-height: 16px;
  top: -20px;
  font-weight: 600;
}
.contact .contact-form #contact .form-group input[type=text] {
  width: 400px;
  background: none;
  position: relative;
  z-index: 2;
}
.contact .contact-form #contact .form-group input:focus + span {
  font-size: 13px;
  line-height: 16px;
  top: -20px;
  font-weight: 600;
}
.contact .contact-form #contact .form-group textarea {
  width: 500px;
  background: none;
  position: relative;
  z-index: 2;
}
.contact .contact-form #contact .form-group textarea:focus + span {
  font-size: 13px;
  line-height: 16px;
  top: -20px;
  font-weight: 600;
}
.contact .contact-form #contact .form-group label.error {
  width: 100%;
  color: red;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}
.contact .contact-form #success, .contact .contact-form #error {
  display: none;
  float: left;
}
.contact .contact-form #error {
  background: red;
  color: #fff;
}
.contact .contact-form #success {
  background: green;
  color: #fff;
}
.contact .contact-form .alert {
  border: none;
  border-radius: 0;
  padding: 20px 30px;
}
.contact .map {
  width: 80%;
  height: 400px;
  display: block;
  background: #222327;
}
.contact .map iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* FOOTER */
.footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  color: #fff;
  //background: url("#{$site-url-prefix}/Images/footer-bg.png") top center no-repeat;
  //background: url('https://citylager24.mxsolutions.info/fileadmin/System/footer-bg.png') top center no-repeat;
  background-size: 80% auto;
}
.footer ul {
  margin: 0;
  padding: 0;
  float: right;
}
.footer ul li {
  display: inline-block;
  margin-left: 10px;
}
.footer ul li a {
  color: #fff;
  font-size: 13px;
}
.footer ul li a:hover {
  color: $site-primary-color; // #75dab4;
}
.footer h6 {
  font-family: "Fjalla One", sans-serif;
  font-size: 2vw;
  margin-bottom: 20px;
}
.footer h2 {
  font-size: 4vw;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 50px;
  opacity: 0.7;
}
.footer .link {
  color: #fff;
  font-size: 17px;
  border-bottom: 1px solid $site-primary-color; // #75dab4;
  padding-bottom: 5px;
}
.footer .link:hover {
  border-color: #fff;
  text-decoration: none;
}
.footer .footer-bar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  padding: 50px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 13px;
}
.footer .footer-bar .copyright {
  margin: 0;
}
.footer .footer-bar .creation {
  margin-left: auto;
}
.footer .footer-bar .creation a {
  color: #fff;
  text-decoration: underline;
}
.footer .footer-bar .creation a:hover {
  text-decoration: none;
  color: $site-primary-color; // #75dab4;
}

/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px), only screen and (max-device-width: 991px) {
  .section-title {
    font-size: 8vw;
    margin-bottom: 50px;
  }

  .page-header .inner {
    padding-left: 160px;
  }

  .all-cases .inner {
    padding-left: 160px;
  }

  .social-media .inner ul li {
    font-size: 4vw;
  }

  .all-cases .inner ul li {
    font-size: 10vw;
  }

  .works {
    padding: 80px 0;
  }

  .works ul li {
    width: 50%;
  }

  .slider .gallery-thumbs .swiper-slide-active span {
    width: 100%;
    font-size: 8vw;
    line-height: 11vw;
  }

  .intro .col-lg-7 {
    padding-left: 15px;
    margin-top: 50px;
  }

  .text-content-block {
    padding: 80px 0;
  }

  .intro-image {
    padding-bottom: 80px;
  }

  .icon-content-block .col-lg-3:nth-child(3) {
    margin-top: 0;
  }

  .icon-content-block .content-block {
    padding: 30px 20px;
  }

  .process {
    margin-bottom: 80px;
  }

  .process .col-lg-3:first-child {
    margin-bottom: 40px;
  }

  .process .odometer {
    font-size: 8vw;
  }

  .testimonials {
    margin-top: 80px;
  }

  .testimonials:before {
    display: none;
  }

  .testimonials .col-lg-6:first-child {
    order: 2;
  }

  .testimonials .col-lg-6:last-child {
    order: 1;
  }

  .testimonials .testimonials-slider {
    margin-top: 40px;
    width: 100%;
  }

  .testimonials .swiper-pagination {
    bottom: 65px;
  }

  .testimonials .testimonial {
    padding: 50px 80px;
  }

  .testimonials .section-title {
    margin-top: 80px;
  }

  .team {
    padding: 80px 0;
  }

  .team:before {
    display: none;
  }

  .team .col {
    width: 33.33333%;
    flex-basis: auto;
    flex-grow: inherit;
  }

  .team figure {
    margin: 30px 0;
  }

  .team figure figcaption {
    color: #fff;
  }

  .team h5 {
    margin-bottom: 40px;
    margin-top: 0;
  }

  .intro {
    padding: 80px 0;
  }

  .clients {
    padding: 80px 0;
  }

  .blog {
    padding: 30px 0;
  }

  .blog .col-lg-9 {
    padding-right: 15px;
  }

  .contact {
    padding: 80px 0;
  }

  .contact .map {
    width: 100%;
    margin: 50px 0;
  }

  .contact .contact-form {
    margin-top: 30px;
  }

  .footer h6 {
    font-size: 26px;
  }

  .footer ul {
    width: 100%;
    float: left;
    margin-bottom: 30px;
  }

  .footer ul li {
    margin-left: 0;
    margin-right: 10px;
  }
}
/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
  .section-title {
    font-size: 9vw;
    margin-bottom: 40px;
  }

  .slider .gallery-thumbs {
    width: 100%;
    margin: 0;
  }

  .page-header .inner {
    padding-left: 100px;
  }

  .page-header .inner p {
    font-size: 17px;
    padding-right: 10%;
  }

  .all-cases .inner {
    padding-left: 100px;
  }

  .all-cases .inner ul li {
    font-size: 13vw;
  }

  .site-navigation .inner {
    padding-left: 0;
  }

  .site-navigation .inner ul li {
    line-height: 1;
  }

  .site-navigation .inner ul li a {
    font-size: 34px;
  }

  .site-navigation .inner ul li i {
    font-size: 20px;
    margin-left: 13px;
  }

  .site-navigation .inner ul li small {
    display: none;
  }

  .site-navigation .inner ul li ul {
    margin-top: 10px;
  }

  .site-navigation .inner ul li ul li a {
    font-size: 22px;
  }

  .left-side {
    width: 80px;
    border-right: none;
  }

  .left-side .follow-us {
    display: none;
  }

  .slide-progress {
    display: none;
  }

  .all-cases-link {
    right: 15px;
  }

  .swiper-button-next {
    right: 20px;
  }

  .swiper-button-prev {
    right: 90px;
  }

  .icon-content-block .col-lg-3:nth-child(3) {
    margin: 50px 0;
  }

  .icon-content-block .content-block {
    padding: 0;
  }

  .icon-content-block .content-block.selected {
    background: none;
    box-shadow: none;
  }

  .icon-content-block .content-block.selected:before {
    display: none;
  }

  .testimonials .testimonial {
    padding: 70px 50px;
  }

  .testimonials .swiper-pagination {
    bottom: 85px;
  }

  .team .col {
    width: 50%;
    flex-basis: 50%;
    flex-grow: inherit;
  }

  .team figure {
    display: block;
    height: auto;
  }

  .team figure img {
    height: auto;
    object-fit: cover;
  }

  .works ul li {
    width: 100%;
  }

  .works ul li:nth-child(2n+2) {
    margin-top: 0;
  }

  .works ul li:nth-child(3n+3) {
    margin-top: 0;
  }

  .clients ul li {
    padding: 30px 20px;
    width: 50%;
  }

  .clients ul li:last-child {
    display: none;
  }

  .blog .post .post-image {
    width: 100%;
    margin-bottom: 40px;
  }

  .blog .post .post-content {
    width: 100%;
    padding: 0 !important;
  }

  .blog .post:nth-child(even) {
    text-align: left;
  }

  .blog .post:nth-child(even) .post-image {
    order: 1;
  }

  .blog .post:nth-child(even) .post-content {
    order: 2;
    text-align: left;
  }

  .blog .post .post-content .post-title {
    font-size: 7vw;
  }

  .blog .post.single .post-content .post-title {
    font-size: 8vw;
  }

  .footer h2 {
    font-size: 7vw;
  }

  .footer h2 br {
    display: none;
  }

  .footer .footer-bar .creation {
    width: 100%;
    display: block;
    margin-top: 5px;
  }
}
/* ANIMATIONS */
@keyframes texteffect {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes texteffect {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes show-img-1 {
  0%, 66.6% {
    visibility: hidden;
  }
  100%, 66.7% {
    visibility: visible;
  }
}
@keyframes show-img-1 {
  0%, 66.6% {
    visibility: hidden;
  }
  100%, 66.7% {
    visibility: visible;
  }
}
@-webkit-keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    transform: scale(0.05, 1);
  }
  66.6% {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  66.7% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}
@keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    transform: scale(0.05, 1);
  }
  66.6% {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  66.7% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }
}

/*# sourceMappingURL=style.css.map */
